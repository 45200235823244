$(document).ready(function() {
  $(
    "#user-range-no-discount, #no-discount-ftp, #no-discount-archive, #no-discount-dvd"
  ).change(function() {
    var $range = String($("#user-range-no-discount").val());
    var $discount = Number($("#discount").val());

    if ($("#no-discount-dvd").is(":checked")) {
      if ($range == "2-5") {
        var $dvd = 5.0;
      } else {
        var $dvd = 10.0;
      }
    } else {
      var $dvd = 0.0;
    }

    if ($("#no-discount-archive").is(":checked")) {
      var $archive = 2.5;
    } else {
      var $archive = 0.0;
    }

    if ($("#no-discount-ftp").is(":checked")) {
      var $ftp = 0.0;
    } else {
      var $ftp = 0.0;
    }

    if ($range == "1") {
      var $base = 34.5;
    } else if ($range == "2-5") {
      var $base = 54.5;
    } else if ($range == "6-10") {
      var $base = 63.5;
    } else if ($range == "11-20") {
      var $base = 74.5;
    } else if ($range == "21-35") {
      var $base = 114.5;
    } else if ($range == "36-50") {
      var $base = 154.5;
    }

    if ($discount > 0) {
      var $total =
        ($base * (1.0 - $discount.toFixed(2) / 100.0) +
          ($ftp + $archive + $dvd)) *
        12;
      var $original = ($base + $ftp + $archive + $dvd) * 12;
      $("#calculated-cost").html(
        $original
          .toFixed(2)
          .toString()
          .replace(".", ",")
      );
      $("#new-calculated-cost").html(
        $total
          .toFixed(2)
          .toString()
          .replace(".", ",")
      );
      $("#new-calculated-cost").addClass("animated flash");
      setTimeout(function() {
        $("#new-calculated-cost").removeClass("animated flash");
      }, 500);
    } else {
      var $total = ($base + $ftp + $archive + $dvd) * 12;
      $("#calculated-cost").html(
        $total
          .toFixed(2)
          .toString()
          .replace(".", ",")
      );
      $("#calculated-cost").addClass("animated flash");
      setTimeout(function() {
        $("#calculated-cost").removeClass("animated flash");
      }, 500);
    }

    $("#total").attr(
      "value",
      $total
        .toFixed(2)
        .toString()
        .replace(".", ",")
    );
  });
});  