var tablesorter = require("tablesorter");

$(document).ready(function() {

  $(function() {
    $('#myTable').tablesorter({
      theme: 'blue',
      headerTemplate: '{content}{icon}',
      sortList: [[1,1]],
      widgets: ["zebra", "filter"],
      widgetOptions : {
        // filter_anyMatch replaced! Instead use the filter_external option
        // Set to use a jQuery selector (or jQuery object) pointing to the
        // external filter (column specific or any match)
        filter_external : '.search',
        // add a default type search to the first name column
        filter_defaultFilter: { 1 : '~{query}' },
        // include column filters
        filter_columnFilters: true,
        filter_placeholder: { search : 'Search...' },
        filter_saveFilters : true,
        filter_reset: '.reset'
      }
      // widgets: ['uitheme'] <- needed for jQuery UI & Bootstrap
    });
  });

    // set search options
    var options = [];
    var searchArray = [];

   $(".dropdown-menu-x a").on("click", function(event) {
    var $target = $(event.currentTarget),
      val = $target.attr('data-value'),
      target = $target,
      $inp = $target.find("input"),
      idx;

    // fake input as checkboxes
    if ((idx = options.indexOf(val)) > -1) {
      options.splice(idx, 1);
      searchArray.splice(idx, 1);
      setTimeout(function() {
        $inp.prop("checked", false);
      }, 0);
    } else {
      options.push(val);
      searchArray.push($target);
      setTimeout(function() {
        $inp.prop("checked", true);
      }, 0);
    }



    let searchStringZielgrupp = "";
    let searchStringArt = "";
    let searchStringLocation = "";

    searchArray.forEach(function(value, idx, array) {
      if (idx === array.length - 1) {
        if (value.attr("data-category") == "zielgrupp") {
          searchStringZielgrupp += "~" + value.attr("data-value");
        } else if (value.attr("data-category") == "art") {
          searchStringArt += "~" + value.attr("data-value");
        } else if (value.attr("data-category") == "location") {
          searchStringLocation += "~" + value.attr("data-value");
        }
      } else {
        if (value.attr("data-category") == "zielgrupp") {
          searchStringZielgrupp += "~" + value.attr("data-value") + "|";
        } else if (value.attr("data-category") == "art") {
          searchStringArt += "~" + value.attr("data-value") + "|";
        } else if (value.attr("data-category") == "location") {
          searchStringLocation += "~" + value.attr("data-value") + "|";
        }
      }
    });

    var cols = [];

    if (searchStringLocation !== "") {
      cols[5] = searchStringLocation;
    }

    if (searchStringArt !== "") {
      cols[3] = searchStringArt;
    }

    if (searchStringZielgrupp !== "") {
      cols[4] = searchStringZielgrupp;
    }

    $("table").trigger("search", [cols]);

    // clear all filters from mobile tiles
    $(".mobile-event").removeClass("filtered-mobile-events");

    // check filter on table row after filters processing and use class name to match with mobile tiles
    $("#eventsTable .filtered").each(function(index, eventObject) {
      $("." + eventObject.classList[0]).addClass("filtered-mobile-events");
    });

    return false;
  });
  
  $(".btn-filter-clear").click(function() {
    searchArray = [];
    options = [];
    $(".filter-checkbox").prop("checked", false);
    $(".mobile-event").each(function(index, eventObject) {
      $(this).removeClass("filtered-mobile-events");
    });
  });
  
});