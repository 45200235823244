$(document).ready(function() {
  // subscription checkbox
  $("#delivery").change(function() {
    if ($(this).is(":checked")) {
      $("#delivery-fields").html(
        '<h5>Rechnungsanschrift:</h5><div class="row">\n' +
          '          <div class="col-md-6">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-company">Firmenname&ast;</label>\n' +
          '              <input type="text" class="form-control" id="delivery-company" name="message[Delivery-Firmenname]" placeholder="Muster GmbH" required>\n' +
          "            </div>\n" +
          "          </div>\n" +
          '          <div class="col-md-6">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-customerId">Verkehrs-/Kd.-Nr.&ast;</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-customerId" name="message[Delivery-Verkehrs-/Kd.-Nr.]" placeholder="F FM 123" required>\n' +
          "            </div>\n" +
          "          </div>\n" +
          "        </div>\n" +
          '        <div class="form-group">\n' +
          '          <label for="Delivery-street">Straße/Haus-Nr.&ast;</label>\n' +
          '          <input type="text" class="form-control" id="Delivery-street" name="message[Delivery-Straße/Haus-Nr.]" placeholder="Musterstr./1" required>\n' +
          "        </div>\n" +
          '        <div class="row">\n' +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-zip">PLZ&ast;</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-zip" name="message[Delivery-PLZ]" placeholder="60327" required>\n' +
          "            </div>\n" +
          "          </div>\n" +
          '          <div class="col-md-8">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-city">Ort&ast;</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-city" name="message[Delivery-Ort]" placeholder="Frankfurt am Main"\n' +
          "                     required>\n" +
          "            </div>\n" +
          "          </div>\n" +
          "        </div>\n" +
          '        <div class="form-group">\n' +
          '          <label for="Delivery-country">Land&ast;</label>\n' +
          '          <input type="text" class="form-control" id="Delivery-country" name="message[Delivery-Land]" placeholder="Deutschland" required>\n' +
          "        </div>\n" +
          '        <div class="row">\n' +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-salutation">Anrede</label>\n' +
          '              <select class="form-control" id="Delivery-salutation" name="message[Delivery-Anrede]">\n' +
          "                <option>Frau</option>\n" +
          "                <option>Herr</option>\n" +
          "                <option>Dr.</option>\n" +
          "                <option>Prof.</option>\n" +
          "              </select>\n" +
          "            </div>\n" +
          "          </div>\n" +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-fname">Vorname&ast;</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-fname" name="message[Delivery-Vorname]" placeholder="Maxx" required>\n' +
          "            </div>\n" +
          "          </div>\n" +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-lname">Nachname&ast;</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-lname" name="message[Delivery-Nachname]" placeholder="Mustermann" required>\n' +
          "            </div>\n" +
          "          </div>\n" +
          "        </div>\n" +
          '        <div class="row">\n' +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-title">Titel</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-title" name="message[Delivery-title]" placeholder="Geschäftsführer">\n' +
          "            </div>\n" +
          "          </div>\n" +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-prefix">Telefon-Vorwahl&ast;</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-prefix" name="message[Delivery-Telefon-Vorwahl]" placeholder="069" required>\n' +
          "            </div>\n" +
          "          </div>\n" +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-phone1">Telefon&ast;</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-phone1" name="message[Delivery-Telefon]" placeholder="xxx xxxx" required>\n' +
          "            </div>\n" +
          "          </div>\n" +
          "        </div>\n" +
          '        <div class="row">\n' +
          '          <div class="col-md-6">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Ansprechpartner">Ansprechpartner</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-subject2" name="message[Delivery-Ansprechpartner]"\n' +
          '                 placeholder="Ansprechpartner elektron. Rechnungsversand">\n' +
          "            </div>\n" +
          "          </div>\n" +
          '          <div class="col-md-6">\n' +
          '            <div class="form-group">\n' +
          '              <label for="E-Mail">E-Mail&ast;</label>\n' +
          '              <input type="text" class="form-control" id="email11" name="message[Delivery-Email]" placeholder="max@mustermann.com" required>\n' +
          "            </div>\n" +
          "          </div>\n" +
          "        </div>\n" +
          '        <div class="form-group">\n' +
          '          <label for="Delivery-subject2">Ansprechpartner</label>\n' +
          '          <input type="text" class="form-control" id="Delivery-subject2" name="message[Delivery-Ansprechpartner]"\n' +
          '                 placeholder="Ansprechpartner elektron. Rechnungsversand">\n' +
          "        </div>\n" +
          '        <div class="form-group">\n' +
          '          <label for="Delivery-email">E-Mail elektron. Rechnungsversand</label>\n' +
          '          <input type="email" class="form-control" id="Delivery-email" name="message[Delivery-E-Mail elektron. Rechnungsversand]" placeholder="maxmustermann@gmx.com"\n' +
          "                 required>\n" +
          "        </div>"
      );
    } else {
      $("#delivery-fields").html("");
    }
  });
});  