$(document).ready(function() {
  
  // hack to fix nav icons display. This is a known issue, reference - https://github.com/OwlCarousel2/OwlCarousel2/issues/1809
  $("#header1 .owl-nav").removeClass("disabled");
  $("#header1 .owl-nav").click(function() {
    $(this).removeClass("disabled");
  });

  // update hidden field for form processing in plugin
  $("#fromEmail").change(function() {
    $("#from-email-hidden").val($(this).val());
  });

  // If browser IE11
  if(!!navigator.userAgent.match(/Trident.*rv\:11\./)){
    $('a[data-lightbox="image-group"]').click(function(e){
      e.preventDefault();
      return false;
    });

    if($('a[data-lightbox="image-group"]').length >= 1){
      $('a[data-lightbox="image-group"]').css({'cursor': 'default'});
    }
  }
});