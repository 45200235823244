$(document).ready(function() {

  // Table Overlay
  $(".content-support table")
  .parent("div")
  .before(
    '<div class="expand" data-toggle="modal" data-target="#tableModal"><i class="fal fa-expand-arrows"></i></div>'
  );
  $(".expand").on("click", function() {
  $("#tableModal .content").html(
    $(this)
      .parent()
      .find("table")
      .clone()
  );
  });

  // Cloning modal content
  /*
  $('div.table-responsive.expandable').each(function () {
  let $content = $(this).find('table.table')[0].outerHTML;
  $(this).find('.content').html($content);
  });
  */
});