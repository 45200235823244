$(document).ready(function() {
  // update placeholder on search
  if (window.location.href.indexOf('search') != -1)  {
    console.log("saSasaS");
    if (window.location.href.indexOf('search-query') == -1) {
      let params = new URL(document.location).searchParams;
      let query = params.get('q');

      document.getElementById('searchBox').className += ' show';
      document.getElementById('search').placeholder = query;
      document.getElementById('search-hilfe').placeholder = query;
    }
  }

  // update placeholder on search - presse
  if (window.location.href.indexOf('search-query') != -1)  {
      let params = new URL(document.location).searchParams;
      let query = params.get('query');
      document.getElementById('search-hilfe').placeholder = query;
  }

  // Trigger search for main search 
  $("#search").keydown(function(e){
    console.log("keydown")
    var code = (e.keyCode ? e.keyCode : e.which);
    if(code == 13) { //Enter keycode
      $("#searchBox form")[0].submit();
      console.log("submit")
    }
  })

  // trigger search for press search 
  $("#search-hilfe").keydown(function(e){
    var code = (e.keyCode ? e.keyCode : e.which);
    if(code == 13) { //Enter keycode
      $(".press-search-form").submit();
      console.log("submit")
    }
  })

  
});  