$(document).ready(function() {

  function fix_visibility() {
    $(".nav-ul-depth-2, .nav-ul-depth-3, .nav-ul-depth-4 ").hide();

    $(".marked.nav-ul-depth-1")
      .children(".active.nav-li-depth-1")
      .children(".nav-ul-depth-2")
      .show();
    $(".marked.nav-ul-depth-1")
      .children(".active.nav-li-depth-1")
      .children(".nav-ul-depth-2")
      .siblings("div")
      .children(".parent-marker")
      .html("-")
      .addClass('toggle');

    if ($(".marked.nav-ul-depth-2")[0]) {
      $(".marked.nav-ul-depth-2").show();

      $(".marked.nav-ul-depth-2")
        .children(".active.nav-li-depth-2")
        .children(".nav-ul-depth-3")
        .show();
      $(".marked.nav-ul-depth-2")
        .children(".active.nav-li-depth-2")
        .children(".nav-ul-depth-3")
        .siblings("div")
        .children(".parent-marker")
        .html("-")
        .addClass('toggle');

      $(".marked.nav-ul-depth-2")
        .siblings("div")
        .children(".parent-marker")
        .html("-")
        .addClass('toggle');
    } else if ($(".marked.nav-ul-depth-3")[0]) {
      $(".marked.nav-ul-depth-3")
        .closest("ul.nav-ul-depth-2")
        .show();
      $(".marked.nav-ul-depth-3").show();

      $(".marked.nav-ul-depth-3")
        .siblings("div")
        .children(".parent-marker")
        .html("-")
        .addClass('toggle');
      $(".marked.nav-ul-depth-3")
        .closest("ul.nav-ul-depth-2")
        .siblings("div")
        .children(".parent-marker")
        .html("-")
        .addClass('toggle');

      $(".marked.nav-ul-depth-3")
        .children(".active.nav-li-depth-3")
        .children(".nav-ul-depth-4")
        .show();
      $(".marked.nav-ul-depth-3")
        .children(".active.nav-li-depth-3")
        .children(".nav-ul-depth-4")
        .siblings("div")
        .children(".parent-marker")
        .html("-")
        .addClass('toggle');
    } else if ($(".marked.nav-ul-depth-4")[0]) {
      $(".marked.nav-ul-depth-4")
        .closest("ul.nav-ul-depth-2")
        .show();
      $(".marked.nav-ul-depth-4")
        .closest("ul.nav-ul-depth-3")
        .show();
      $(".marked.nav-ul-depth-4").show();

      $(".marked.nav-ul-depth-4")
        .siblings("div")
        .children(".parent-marker")
        .html("-")
        .addClass('toggle');
      $(".marked.nav-ul-depth-4")
        .closest("ul.nav-ul-depth-3")
        .siblings("div")
        .children(".parent-marker")
        .html("-");
      $(".marked.nav-ul-depth-4")
        .closest("ul.nav-ul-depth-2")
        .siblings("div")
        .children(".parent-marker")
        .html("-")
        .addClass('toggle');
    }
  }

  fix_visibility();

  $(".parent-marker-1").click(function() {
    let $parent = $(this)
      .parent()
      .parent();
    $parent.find("ul.nav-ul-depth-2").slideToggle();
    if ($(this).html() === "+") {
      $(this).html("-");
      $(this).addClass('toggle');
    } else {
      $(this).html("+");
      $(this).removeClass('toggle');
    }
  });
  $(".parent-marker-2").click(function() {
    let $parent = $(this)
      .parent()
      .parent();
    $parent.find("ul.nav-ul-depth-3").slideToggle();
    if ($(this).html() === "+") {
      $(this).html("-");
      $(this).addClass('toggle');
    } else {
      $(this).html("+");
      $(this).removeClass('toggle');
    }
  });
  $(".parent-marker-3").click(function() {
    let $parent = $(this)
      .parent()
      .parent();
    $parent.find("ul.nav-ul-depth-4").slideToggle();
    if ($(this).html() === "+") {
      $(this).html("-");
      $(this).addClass('toggle');
    } else {
      $(this).html("+");
      $(this).removeClass('toggle');
    }
  });



  // Mobile Toggle Support Nav

  $("body").click(function(evt) {
    if (evt.target.id == "show-sidenav") {
      evt.preventDefault;
      $("nav.side-navigation").toggleClass("open");
      return;
    }
    if (!$(evt.target).closest("nav.side-navigation.open").length)
      $("nav.side-navigation").removeClass("open");
    //Do processing of click event here for every element except with id menu_content
  });
  
});  