$(document).ready(function() {

  $(".accordion .card-header").on("click", function() {
    $(this)
      .parent()
      .parent()
      .find(".card-header.active")
      .not(this)
      .removeClass("active");
    $(this).toggleClass("active");
  });
  
  if (window.location.pathname === "/ueber-uns/kontaktpersonen/" || window.location.pathname === "/kontakt/kontaktpersonen/") {
    if (window.location.hash != '') {
      var full_hash = location.href.split("#")[1] || "";      
      var split_hash = full_hash.split('+');
      $('#' + decodeURIComponent(split_hash[0]).replace(/\s/g, "")).find( ".trigger-open" ).addClass( "show" );
   
      if (split_hash[1]) {
        setTimeout(function() {
          document.getElementById(full_hash).scrollIntoView();
        }, 700);  
      }   
    }
    
  };
  
});  