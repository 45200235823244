var ImagesLoaded = require("imagesloaded");
var jQueryBridget = require("jquery-bridget");
var Masonry = require("masonry-layout");

jQueryBridget("masonry", Masonry, $);
jQueryBridget("imagesLoaded", ImagesLoaded, $);

$(document).ready(function() {
  // Owl Carousels
  $(".owl-one").imagesLoaded(function() {
    $(".owl-one").owlCarousel({
      items: 12,
      loop: true,
      margin: 0,
      merge: true,
      nav: true,
      dots: true,
      navText: ['', ''],
      addClassActive: true,
      autoplay: 6000,
      autoplayTimeout: 6000,
      autoplaySpeed: 2000,
      autoplayHoverPause: false,
      responsive: {
        0: {
          merge: false,
          items: 1
        },
        678: {
          mergeFit: true
        },
        1000: {
          mergeFit: false
        }
      },
      // disable all owl interaction if carousel only has one item
      onInitialized: function(event) {
        if (event.item.count <= 1) {
          this.options.loop = false;
          this.options.dots = false;
          this.options.mouseDrag = false;
          this.options.touchDrag = false;
          this.options.pullDrag = false;
          this.options.freeDrag = false;
          this.options.grabClass = null;
        }
        this.refresh();
      }
    });
    });
    
  $(".owl-two").owlCarousel({
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    navText: ["", ""],
    addClassActive: true,
    center: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 3
      }
    }
  });

  $(".owl-three").imagesLoaded(function() {
    $(".owl-three").owlCarousel({
      items: 1,
      loop: true,
      navText: [
        "<i class='fal fa-arrow-left'></i>",
        "<i class='fal fa-arrow-right'></i>"
      ],
      smartSpeed: 900,
      singleItem: true,
      responsive: {
        0: {
          dots: true,
          nav: false,
          stagePadding: 0
        },
        768: {
          dots: false,
          nav: true,
          stagePadding: 150,
          margin: 30
        }
      }
    });
  });

  $(".owl-four").imagesLoaded(function() {
    $(".owl-four").owlCarousel({
      items: 1,
      autoplay: 6000,
      autoplayTimeout: 6000,
      autoplaySpeed: 2000,
      autoplayHoverPause: true,
      loop: true,
      navText: [
        "<i class='fal fa-arrow-left'></i>",
        "<i class='fal fa-arrow-right'></i>"
      ],
      smartSpeed: 900,
      singleItem: true,
      responsive: {
        0: {
          dots: true,
          nav: false,
          stagePadding: 0
        },
        768: {
          dots: false,
          nav: true,
        }
      }
    });
  });

  $(".owl-three-single").imagesLoaded(function() {
    $(".owl-three-single").owlCarousel({
      items: 1,
      loop: false,
      dots: false,
      nav: false,
      singleItem: true
    });
  });

  $(".intro-owl-carousel").owlCarousel({
    singleItem: true,
    nav: false,
    dots: true,
    items: 1,
    itemsDesktop: false,
    itemsDesktopSmall: false,
    itemsTablet: false,
    itemsMobile: false
  });

  setTimeout(function () {
    $( ".owl-stage" ).each(function() {
      if ($(".owl-dots .owl-dot").length == 1) {
        $("div.owl-dots button").hide();
      }   
    });
  }, 100);

  // $(".boxslide").each(function (index, element) {
  //   if (window.document.documentMode) {
  //     $(this).find('.boxSlide-picture').remove();     
  //   } else {
  //     $(this).css('background-image','none');
  //   } 
  // });

  // Masonry Layout
  var $grid = $(".grid");
  $grid.imagesLoaded(function() {
    $grid.masonry({
      itemSelector: ".grid-item",
      columnWidth: ".grid-item",
      gutter: 20,
      horizontalOrder: true
    });
  });

  // Need to update every time click happens on team accordions 
  var masonryUpdate = function() {
    setTimeout(function() {
        $('.grid').masonry({
          gutter: 10,   
          itemSelector: ".grid-item",
          columnWidth: ".grid-item",
          horizontalOrder: true  
        });
    }, 5);
  }

  $('.team-masonry-fix').on('click', masonryUpdate);

  $("img.lazy").lazyload();

  /**
   * Button for expanding client logos on mobile start page
   */
  $('#clientexpander').on('click', function () {
    if ($('.partner .container').css('max-height') !== 'none') {
      $('.partner .container').css('max-height', 'none');
      $(this).html('<i class="fal fa-angle-up"></i>');
      $(this).removeClass('gradient');
    } else {
      $('.partner .container').css('max-height', '');
      $(this).html('<i class="fal fa-angle-down"></i>');
      $(this).addClass('gradient');
    }
  });

});