"use strict";

require("./head.js");

// script to extract url parameters
import './modules/get-url-vars';

// script to add layouts with masonry, owlCarousel, lazyload, imagesloaded
import './modules/layout';

// script to fix accordion visibility
import './modules/accordion';

// help pages - table overlay for expand table functionality
import './modules/hilfe-table-overlay';

// help pages - navigation, navigation search box, mobile layout fix
import './modules/hilfe-navigation';

// event page - anchor scroll
import './modules/event-anchor-scroll';

// subscription form - address checkbox - add new fields if billing address is different
import './modules/subscription-form-address-checkbox';

// subscription form - calculte total price
import './modules/subscription-form-price-calculation';

// form submit
import './modules/form-submit';

// Search prompt - update the input field according to url parameters 
import './modules/search-input-field';

// miscellaneous
import './modules/miscellaneous';

// script to extract url parameters
import './modules/table-sort';

// cookie consent
import './modules/cookie-opt-in';

//event-filter
import './modules/event-filter'